import { useRef, useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import s from './CopyToClipboardField.module.scss';
import { ReactComponent as CheckmarkIcon } from 'img/checkmark.svg';
import { ReactComponent as CopyIcon } from 'img/copy-icon.svg';

type CopyToClipboardFieldProps = {
  label: string;
  value: string;
};

const CopyToClipboardField = ({ label, value }: CopyToClipboardFieldProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const inputEl = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isChecked) {
      timeoutRef.current = setTimeout(() => {
        setIsChecked(false);
      }, 2000);
    }
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isChecked]);

  const copyInputValueToClipboard = () => {
    inputEl.current?.select();

    if (navigator.clipboard) {
      navigator.clipboard.writeText(value);
    }

    // Do we still need to support IE?
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();
    inputEl.current?.blur();
    setIsChecked(true);
  };

  const canUseCopy =
    document.queryCommandSupported && document.queryCommandSupported('copy');

  return (
    <div className={s.root}>
      <Input
        label={label}
        className={s.input}
        ref={inputEl}
        type="text"
        value={value}
        readOnly
        onClick={() => inputEl.current?.select()}
      />
      {canUseCopy && (
        <div className={s.copyButtonWrapper}>
          <Button
            type="button"
            variant="secondary"
            onClick={copyInputValueToClipboard}
            className={s.button}
            data-testid="copyButton"
            round={true}
            tooltip="Kopiera"
          >
            {isChecked ? <CheckmarkIcon /> : <CopyIcon />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CopyToClipboardField;
